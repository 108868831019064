import React from "react"
import PageWrapper from "../components/PageWrapper"
import Head from "../components/head"
import { JsonLd } from "../components/JsonLd"
import Causes from "../sections/conditions/ing/ingcauses.js"
import imgCustomer5 from "../assets/image/jpeg/extract1.jpg"
import imgCustomer6 from "../assets/image/jpeg/about-d.jpg"
import imgCustomer7 from "../assets/image/jpeg/hydra.jpg"
import FeatureCard from "../components/Conditions.js"
import styled from "styled-components"
import Trend from "../components/trends.js"
import Saveface from "../components/save.js"
import HeroComponent from "../components/ImageHero.js"
import imgVideo from "../assets/image/jpeg/ingrown1.jpg"
import { Container, Row, Col } from "react-bootstrap"
import { Box, Section } from "../components/Core"

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before,
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`

const titleText = "Ingrown Hairs"
const textContent = ""

const IngrownPage = () => (
  <>
    <Head
      title="Ingrown Hairs Condition in London UK"
      image="/images/dermamina-home.jpg"
      description="Balance or straighten your nose in a same-day appointment. A non-surgical rhinoplasty using dermal fillers is a safe, and non-invasive treatment. "
      keywords="Nose jobs, Liquid nose job, Liquid rhinoplasty, non Surgical nose job London, Nose filler, Non surgical rhinoplasty, Non surgical nose job, Pain free nose job, non surgical rhinoplasty London, non surgical nose job harley street"
    />

    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "Organization",
        "@id": "https://www.dermamina.com/#organization",
        url: "https://www.dermamina.com",
        name: "Dermamina",
        contactPoint: {
          "@type": "ContactPoint",
          telephone: "020 7099 6650",
          contactType: "Customer service",
        },
        address: {
          "@type": "PostalAddress",
          streetAddress: "114 New Cavendish Street",
          addressRegion: "London",
          postalCode: "W1W 6XT",
          addressLocality: "Westminster",
        },
        image: {
          "@type": "ImageObject",
          url: "https://dermamina.com/images/dermamina-home.jpg",
        },
        logo: {
          "@type": "ImageObject",
          url: "https://dermamina.com/images/dermamina-logo.svg",
          width: "200",
        },
        name: "dermamina",
        sameAs: [
          "https://www.facebook.com/DermaMina-1985252831708400/",
          "https://www.instagram.com/dermamina",
        ],
      }}
    </JsonLd>

    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "Dermamina Clinic",
            item: "https://www.dermamina.com",
          },

          {
            "@type": "ListItem",
            position: 2,
            name: "Non-Surgical Rhinoplasty",
            item: "https://www.dermamina.com/non-surgical-nose-job",
          },

          {
            "@type": "ListItem",
            position: 3,
            name: "Tear Trough Fillers Under Eye",
            item: "https://www.dermamina.com/tear-trough-under-eye",
          },
        ],
      }}
    </JsonLd>
    <PageWrapper headerDark footerDark>
      <HeroComponent
        itext='<span style="color: #1a1a1a;">Ingrown</span> <span style="color: #00aec7;">Hairs</span>'
        secondText="One of the most common skin problems"
        videoAlt="Ingrown Hairs"
        imgVideo={imgVideo} // Specify your image URL here
      />
      <Separator />
      <Section bg="#f7f7fb" py={4}>
        <Container className="pt-3 pb-3">
          <Row className="pb-4">
            <Col>
              <SecondText>Treatments For Ingrown Hairs</SecondText>
            </Col>
          </Row>
        </Container>

        <div className="d-md-flex d-sm-block">
          <FeatureCard
            color="secondary"
            title="Laser Hair Removal →"
            imageSrc={imgCustomer5}
            to="/laser-hair-removal"
          ></FeatureCard>

          <FeatureCard
            color="secondary"
            title="Extract + Rejuvenate →"
            imageSrc={imgCustomer6}
            to="/advanced-facial-treatments-london/#lux"
          ></FeatureCard>

          <FeatureCard
            color="secondary"
            title="Hydrafacial →"
            imageSrc={imgCustomer7}
            to="/hydrafacial"
          ></FeatureCard>
        </div>
      </Section>
      <SeparatorEnd />
      <Causes />

      <Saveface />
      <Trend />
    </PageWrapper>
  </>
)
export default IngrownPage
